import { Affix, Col, Progress, Row } from 'antd';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import logo from '../../images/logo@2x.png';
import yt from '../../images/youtube-icon.svg';
import twit from '../../images/twiter-icon.svg';
import fb from '../../images/fb-icon.svg';
import nabp from '../../images/nabp-footer.svg'
const Footer = () => {

    const { progress } = useAuth();

    const navigate = useNavigate();

    const location = useLocation();

    const showDisclaimer = location.pathname === '/' && false;

    const nav = (path) => {
        navigate(path);
    }

    const external = (path) => {
        window.open(path, '_blank');
    };


    const progressbar = !!progress && (
        <Affix offsetBottom={20}>
            <Progress 
                percent={progress}
                className='max-w-md progress-wrapper'
                strokeLinecap='butt' 
                trailColor='pink'
                strokeColor='green'
            />
        </Affix>
    );

    return (
        <>
            {progressbar}
            <section className='footer-section'>
                <div className='content'>
                    <Row>
                        <Col xs={24} xm={24} md={12}>
                            <button
                                className="leading-relaxed inline-block py-2 whitespace-nowrap uppercase text-white"
                                onClick={() => nav('/')}
                            >
                                <img alt='CVS logo' src={logo} className='logo' />
                            </button>
                        </Col>
                        <Col xs={24} xm={24} md={12}>
                            <div
                                className={
                                "lg:flex flex-grow items-center 2xl:height-[113px] xl:height-[113px]" +
                                (true ? " flex" : " hidden")
                                }
                                id="example-navbar-danger"
                            >
                                <ul className="flex flex-col lg:flex-column list-none lg:ml-auto transition-colors duration-300 ">
                                    <li className="nav-item">
                                        <button
                                            className="px-3 py-2 flex items-center uppercase leading-snug hover:opacity-75" 
                                            onClick={() => nav('/')}
                                        >
                                            <span className="ml-2">priority panel home</span>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className="px-3 py-2 flex items-center leading-snug hover:opacity-75" 
                                            onClick={() => external('https://www.cvs.com/clinicaltrials/clinical-trials-faqs')}
                                        >
                                            <span className="ml-2">FAQs</span>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className="px-3 py-2 flex items-center uppercase leading-snug hover:opacity-75" 
                                            onClick={() => external('https://www.cvs.com/clinicaltrials')}
                                        >
                                            <span className="ml-2 text-left">cvs clinical trials</span>
                                        </button>
                                    </li>
                                
                                </ul>
                            </div>
                        </Col>
                        <Col xs={24} xm={24} md={12}>
                           
                        </Col>
                        <Col xs={24} xm={24} md={12}>
                            <div
                                className={
                                "lg:flex flex-grow items-center 2xl:height-[113px] xl:height-[113px] social" +
                                (true ? " flex" : " hidden")
                                }
                                id="example-navbar-danger"
                            >
                                <ul className="flex flex-col lg:flex-column list-none lg:ml-auto transition-colors duration-300 ">
                                    <li className="nav-item">
                                        <span className="px-3 py-2 flex items-center leading-snug hover:opacity-75">FOLLOW US</span>
                                    </li>
                                    <li className="nav-item">
                                        <img className="px-3 py-2 flex items-center leading-snug hover:opacity-75" src={fb} />
                                    </li>
                                    <li className="nav-item">
                                        <img className="px-3 py-2 flex items-center leading-snug hover:opacity-75" src={twit} />
                                    </li>
                                    <li className="nav-item">
                                        <img className="px-3 py-2 flex items-center leading-snug hover:opacity-75" src={yt} />
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='footer-bottom'>
                <div className='content'>
                    <a className='links' href='https://www.cvs.com/help/privacy_policy.jsp' target={'_blank'}>Manage Email Preferences</a>
                    <span className='spacer'>|</span> 
                    <a className='links' href='https://www.cvs.com/help/privacy_policy.jsp' target={'_blank'}>Terms of Use</a> 
                    <span className='spacer'>|</span> 
                    <a className='links' href='https://www.cvs.com/help/privacy_policy.jsp' target={'_blank'}>Accessibility</a> 
                    <span className='spacer'>|</span> 
                    <a className='links' href='https://www.cvs.com/help/privacy_policy.jsp' target={'_blank'}>Privacy Policy</a> 
                    <span className='spacer'>|</span> 
                    <a className='links' href='https://www.cvs.com/help/privacy_policy.jsp' target={'_blank'}>Do Not Sell or Share My Personal Information</a> 
                    <span className='spacer'>|</span> 
                    <a className='links' href='https://www.cvs.com/help/privacy_policy.jsp' target={'_blank'}>Contact Us</a> 
                    <span className='spacer'>|</span> 
                    <a className='links' href='https://www.cvs.com/help/privacy_policy.jsp' target={'_blank'}>Return Policy</a> 
                    
                </div>
                <br />
                <img className='nabp' alt='N A B P accredited' src={nabp} />
                <br />
                <span className='copyright'>© Copyright 1999-{new Date().getFullYear()} CVS.com</span>
                <br />
            </section>
        </>
    );
}

export default Footer;