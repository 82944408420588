import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MainLayout from './components/MainLayout';
import Dynamic from './components/Dynamic';
import Test from './components/Test';
import DQ from './components/DQ';
import ScreenQualified from './components/ScreenQualified';
import AlreadyScreened from './components/AlreadyScreened';
import DeclinedParticipation from './components/DeclinedParticipation';
import NoSite from './components/NoSite';
import FAQ from './components/FAQ';
import Landing from './components/Landing';
import About from './components/About';
import AboutClinicalResearch from './components/AboutClinicalResearch';
import SourceCallCenter from './components/SourceCallCenter';
import SourceDirectMail from './components/SourceDirectMail';
import SourceDigitalHome from './components/SourceDigitalHome';
import Questionnaire from './components/Questionnaire';

const isSbx = false;

const basename = isSbx ? '/cvs-patient-panel-staging' : undefined;

const App = () => {
	// the router basename will have to be modified if this site is being hosted in a sub-directory, instead of root
	// e.g., if the app is hosted in /insert-study-name-here, the basename should be '/insert-study-name-here'
	// if the app is being debugged with a basename, then you will have to navigate to 'localhost:XXXX/basename'
	return (
		<Router basename={basename}>
			<Routes>
				<Route element={<MainLayout />}>
					<Route path='/' element={<Landing />} />
					<Route path='/faqs' element={<FAQ />} />
					<Route path='/about' element={<About />} />
					<Route path='/clinical-research' element={<AboutClinicalResearch />} />
					<Route path='/questionnaire' element={<Questionnaire />} />

					{/* Webscreener result pages */}
					<Route path='/pq' element={<ScreenQualified />} />
					<Route path='/dq' element={<DQ />} />
					<Route path='/already-screened' element={<AlreadyScreened />} />
					<Route path='/no-participate' element={<DeclinedParticipation />} />
					<Route path='/no-site' element={<NoSite />} />

					{/* CVS source collection routes */}
					<Route path='/callcenter' element={<SourceCallCenter />} />
					<Route path='/directmail' element={<SourceDirectMail />} />
					<Route path='/digitalhome' element={<SourceDigitalHome />} />
				</Route>
			</Routes>
		</Router>
	);
}

export default App;
