/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import doIQualify from '../images/Do I Qualify Button@2x.png';
import joinUsHero from '../images/cta-background@2x.png';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import '../styles/JoinUs.css';

const questionnaireLink = '/questionnaire';

const JoinUs = () => {
    const { doConversion } = useAuth();
    const navigate = useNavigate();

    const nav = (page) => {
        if (page === questionnaireLink)
            doConversion();
        navigate(page);
    };

    return (
        <section 
                className='join-us-section'
               
            >
                <div className='content'  style={{
                    background: `transparent url('${joinUsHero}') 0% 0% no-repeat padding-box`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 1,
                }}>
                    <p className='header'>
                    Get Notified as New Clinical Trials Launch
                    </p>
                    <p className='info'>
                    Sign up today and get notified about future studies that may benefit you or a loved one.
                    </p>
                    <p>
                    <div onClick={() => nav('/questionnaire')} className='conversion-button do-i-qualify-button p-[13px]'>
                        <span>Sign Up</span>
                    </div>
                    </p>
                </div>
            </section>
    )
};

export default JoinUs;