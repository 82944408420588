/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Tooltip, Skeleton, Form as AntForm } from 'antd';
import { Form, Item } from './Toolkit/Form';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import parse, { domToReact } from 'html-react-parser';
import check from '../utils/dependencyCheck';
import InputMechanism from './Toolkit/InputMechanism';
import LookupSearch from './Toolkit/LookupSearch';
import { gtag, install } from 'ga-gtag';
const { useForm } = AntForm;

export const Dynamic = ({ qGuid, isSbx = true }) => {

    const url = isSbx ? 'coreapisb.retain3d.com' : 'coreapi.retain3d.com';
    const upsertUser = ({ guid, payload }) => axios.post(`https://${url}/V1/API/Questionnaires/${guid}`, [payload]);

    // const [user, setUser] = useState();
    // const [progress, setProgress] = useState();
    // const [isAuthenticated, setIsAuthenticated] = useState();
    const [externalId, setExternalId] = useState();
    const [source, setSource] = useState();

    const [installedGtag, setInstalledGtag] = useState(false);
    const [form] = useForm();

    const [guidObject, setGuidObject] = useState();
    const [lead, setLead] = useState({});

    const [d, setD] = useState();
    const [page, setPage] = useState();
    const [dStack, setDStack] = useState([]);
    const [determinants, setDeterminants] = useState([]);

    const [isActionLoading, setIsActionLoading] = useState(false);
    
    const getCurrentD = () => determinants?.find(x => x.guid === d);

    const nextD = () => {
        if (dStack?.length < determinants?.length)
            setDStack(_d => [..._d, determinants[dStack.length]?.guid]);
    }

    const handleSubmit = (values) => {
        const callback = () => {
            nextD();
        };

        let payload = { ...values };
        if (guidObject)
            payload['SmartTag'] = guidObject;

        const currDom = window.location.hostname.toLowerCase();
        if (source) {
            payload['4181'] = source;
        } 
        else if (currDom.endsWith('cvsasthmacallcenter.com'))
            payload['4181'] = 'Call Center';
        else if (currDom.endsWith('cvsasthma.com'))
            payload['4181'] = 'Direct Mail';

        if (externalId) {
            payload['4166'] = externalId;
            setExternalId();
        }

        mutate({ payload, guid: qGuid, callback });
        //nextD();
    };

    const saveFormValues = (values) => {
        form.setFieldsValue(values);
        handleBlur(values);
    };

    const handleBlur = (values) => {
        
        var payload = {};
        var varNames = Object.keys(values);
        varNames.forEach(v => {
            //if (!!values[v] && values[v] !== '')
            payload[v] = values[v];
            // gtag('event', 'select_content', {
            //     'content_type': 'v1',
            //     'item_id': v
            // });
        });

        if (Object.keys(payload).length > 0)
            setLead(_lead => ({ ..._lead, ...payload }));
    };

    const handleLookupValues = (values) => {
        form.setFieldsValue(values);
        handleBlur(values);
    };

    /**
     * Perform dynamic response actions
     * @param {Array} args.items response action items
     * @param {Array} args.callback continue callback
     * @param {Array} args.guid object guid
     * @returns 
     */
    const doResponseActions = (args) => {

        args = { items: [], guid: null, ...args };

        const { items, callback, guid } = args;

        setIsActionLoading(true);
        const editItems = items.filter(i => i.responseType === 'setValues');
        if (editItems?.length > 0) {
            let payload = {};
            editItems?.forEach(i => {
                payload[i.idField] = i.html;
            });

            payload.SmartTag = guid;
            
            upsertUser({ guid: qGuid, payload })
                .then(resp => {
                    //console.log('editItems response', resp);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        const navigateItem = items.find(i => i.responseType === 'navigate');
        if (!!navigateItem) {
            window.location.assign('/' + navigateItem.html);
            return;
        }

        const newPageItem = items.find(i => i.responseType === 'newPage');
        if (!!newPageItem)
            setPage(d);

        const continueItem = items?.find(i => i.responseType === 'continue');
        if (continueItem)
            callback?.();

        setIsActionLoading(false);
    };

    const handleUpsertUserSettled = (response, error, { callback }) => {
        if (error) {
            console.error(error);
        } else if (response?.data?.length > 0 && !!response.data[0].guid) {
            const obj = response.data[0];
            setGuidObject(obj.guid);
            const currD = getCurrentD();

            let items = [];
            currD.items?.forEach(i => {
                const valid = !i.dependency || check(
                    i.dependency.dependencyType,
                    i.dependency.vals,
                    lead[i.dependency.idSourceField] ?? obj[i.dependency.idSourceField],
                    i.dependency.sourceNode,
                    { ...(lead ?? {}), ...(obj ?? {})},
                    null
                );

                if (valid)
                    items.push(i);
            });
            doResponseActions({ items, callback, guid: obj.guid });
        }
    };

    const reloadPage = () => window.location.reload();

    const { mutate, isError, isLoading: isUpdateLoading } = useMutation(
        upsertUser,
        { onSettled: handleUpsertUserSettled }
    );

    const errorMsg = isError ? (
        <div class="z-50 fixed top-10 left-0 right-0 flex items-center bg-red-400 text-white text-sm font-bold px-4 py-3 mb-4 max-w-2xl m-auto animated animatedFadeInUp fadeInUp" role="alert">
            <svg class="fill-current w-4 h-4 mr-2" viewBox="0 0 20 20"><path d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>            
            <p>
                We were unable to process your request, click 
                <Button size='small' className='text-white m-0 p-0 hover:text-white underline' type='link' onClick={reloadPage}>&nbsp;<u>here</u>&nbsp;</Button> 
                to reload the page
            </p>
        </div>
    ) : null;
    
    const submitButton = (
        <div className='text-center'>
            <Tooltip title='Continue to the next step'>
                <Button 
                    type='primary' 
                    htmlType='submit' 
                    loading={isUpdateLoading || isActionLoading}
                    className='animated animatedFadeInUp fadeInUp text-white hover:text-white active:bg-blue-600 hover:filter-none'
                >
                    {determinants?.find(x => x.guid === d)?.header ?? 'Save'}
                </Button>
            </Tooltip>
        </div>
    );

    const getBlock = ({ guid, header, items, type, html }) => {

        const handleCoreResponse = ({ address, city, state, postalCode }) => {
            const addressItem = items?.find(x => x.addressType === 'Street1');
            const cityItem = items?.find(x => x.addressType === 'City');
            const stateItem = items?.find(x => x.addressType === 'State');
            const postalItem = items?.find(x => x.addressType === 'Postal');
            let newValues = {};
            if (addressItem) newValues[addressItem.idField] = address;
            if (cityItem) newValues[cityItem.idField] = city;
            if (stateItem) newValues[stateItem.idField] = state;
            if (postalItem) newValues[postalItem.idField] = postalCode;
            saveFormValues(newValues);
        };

        const transform = (domNode, index) => {
            const { attribs: attributes = {}, name, children } = domNode;
            // fixes issue where <div> is child of <p> (invalid HTML). Changes <p> -> <div>
            if (attributes['questionnaire-type'] === 'block') {
                return <div {...attributes}>{header && parse(header)}</div>;
            } else if (attributes['questionnaire-type'] === 'items') {
                if (type === 'lookupSearch')
                    return (
                        <LookupSearch 
                            guidObject={guidObject} 
                            items={items} 
                            guid={guid} 
                            onChange={handleLookupValues} 
                            onNoResults={doResponseActions}
                        />
                    );
                return (
                    <div key={guid} {...attributes}>
                        {items?.map(i => {
                            let rules = i.required ? [{
                                required: true,
                                message: i.requiredHtml ?? 'Required'
                            }] : [];

                            i?.validationRules.forEach(v => {
                                const vRule = getRule(v);
                                if (!!vRule)
                                    rules.push(vRule);
                            });

                            if (i?.confirmOnly)
                                rules.push(({ getFieldValue }) => ({
                                    validateTrigger: 'onBlur',
                                    validator(_, value) {
                                        if (!value || getFieldValue(`${i.idField}`) === value) {
                                            return Promise.resolve();
                                        }
    
                                        return Promise.reject(new Error(parse(i.confirmOnlyHtml ?? 'Mismatch')));
                                    },
                                }))

                            const extraRender = !!i.extraHtml ? parse(i.extraHtml) : null;
                            let extra = extraRender;
                            if (!!i.extraTooltipHtml)
                                extra = <Tooltip title={parse(i.extraTooltipHtml)}>{extraRender}</Tooltip>

                            return (
                                <Item 
                                    name={i?.confirmOnly ? `${i.idField}_confirmOnly` : `${i.idField}`} 
                                    extra={extra}
                                    label={!!i.html && parse(i.html)}
                                    rules={rules}
                                    dependencies={i?.confirmOnly ? [`${i.idField}`] : undefined}
                                    validateTrigger={i?.confirmOnly ? 'onBlur' : undefined}
                                    hasFeedback={i?.confirmOnly}
                                >
                                    <InputMechanism item={i} />
                                </Item>
                            )
                        })}
                    </div>
                );
            } 

            return domToReact(domNode, options);
        };

        // replace placeholders in HTML layout with proper elements
        const options = {
            replace: transform,
        };

        if (['field', 'lookupSearch'].includes(type))
            return parse(html ?? published?.blockTemplate, options);
        else if (type === 'content')
            return parse(html);
        return null;
    }

    const getRule = (validationRule) => {
        switch (validationRule.type) {
            case 'isUSPostalCode':
                return { 
                    required: true, 
                    message: validationRule.message,
                    validator: (_, value) => {
                        if (!value || `${value}`.length < 4)
                            return Promise.resolve(); // ignore if no value
                        
                        //https://stackoverflow.com/questions/160550/zip-code-us-postal-code-validation
                        if (`${value}`.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)) 
                            return Promise.resolve();
                         
                        return Promise.reject();
                    }  
                }
            case 'dateGreaterThan':
            case 'dateLessThan':
                return { 
                    required: true, 
                    type: 'date', 
                    message: validationRule.message,
                    validator: (_, value) => {
                        if (!value)
                            return Promise.resolve(); // ignore if no value

                        const date = new Date(`${value}T12:00:00`);
                        const dateForComparison = new Date(`${validationRule.value}T12:00:00`);
                        if (validationRule.type === 'dateGreaterThan' && date.getFullYear() > dateForComparison.getFullYear()) 
                            return Promise.resolve();
                        if (validationRule.type === 'dateLessThan' && date.getFullYear() < dateForComparison.getFullYear())
                            return Promise.resolve();
                         
                        return Promise.reject();
                    }
                     
                }
            case 'isEmailAddress':
                return { required: false, type: 'email', message: validationRule.message }
            case 'isLocalPhoneNumber':
                return { 
                    required: true, 
                    type: 'date', 
                    message: validationRule.message,
                    validator: (_, value) => {
                        if (!value || value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/))
                            return Promise.resolve();

                        return Promise.reject();
                    }
                     
                }
            default:
                break;
        }
        return null;
    };

    const handleSuccess = (data) => {
        form.resetFields();
        setLead({});
        setPage(data?.guid);
    }

    const {
        isLoading,
        data: published,
        refetch
    } = useQuery(
        ['structure', qGuid],
        () => axios.get(`https://${url}/V1/questionnaires/${qGuid}/structure`),
        { 
            select: response => response?.data,
            onSuccess: handleSuccess,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        
        if (!installedGtag) {
            setInstalledGtag(true);
            install('G-ST822CD40Q');
        }


        const currPage = published?.pages?.find(p => p.guid === page);
            
        let groups = [];
        currPage?.groups?.forEach(g => {
            const dep = g.dependency;
            let isValid = !dep;
            if (!isValid)
                isValid = check(
                    dep.dependencyType,
                    dep.vals,
                    lead[dep.idSourceField],
                    dep.sourceNode,
                    lead,
                    null
                ) || false;

            if (isValid)
                groups.push(g);
        });

        let determinantors = [];
        groups?.forEach(g => {
            g.blocks?.forEach(b => {
                if (b.type === 'determinator')
                    determinantors.push(b);
            });
            
        });

        setDeterminants(determinantors);

    }, [lead, page, dStack]);

    const blockRender = useMemo(() => {
        
        if (!!published && !!page) {
            // Dependency check goes here
            const pages = published.pages;
            
            const currPage = pages?.find(p => p.guid === page);
            
            let groups = [];
            currPage?.groups?.forEach(g => {
                const dep = g.dependency;
                let isValid = !dep;
                if (!isValid)
                    isValid = check(
                        dep.dependencyType,
                        dep.vals,
                        lead[dep.idSourceField],
                        dep.sourceNode,
                        lead,
                        null
                    ) || false;

                if (isValid)
                    groups.push(g);
            });

            let questionBlocks = [];
            let foundD = false;
            groups?.forEach(g => {
                g.blocks?.filter(b => b.type === 'field' || (b.type ))?.forEach(b => {
                    if (['field', 'lookupSearch', 'content'].includes(b.type) && (!b.guidDeterminator || (b.guidDeterminator && dStack.includes(b.guidDeterminator)))) {
                        questionBlocks.push(b);
                    } else if (!foundD && b.type === 'determinator' && !dStack.includes(b.guid)) {
                        setD(b.guid);
                        foundD = true;
                    } 
                });
                
            });

            return questionBlocks?.map(b => <div key={['block', b.guid]}>{getBlock(b)}</div>);
        } 

        return <Skeleton loading />

        //const filteredBlocks = blocks?.filter(b => !b.guidDeterminator || (!!b.guidDeterminator && determinants.includes(b.guidDeterminator)));
        //return filteredBlocks?.map(b => getBlock(b));
       
    }, [lead, page, dStack]);

    const styleRender = useMemo(() => {
        if (published?.style && published?.style !== '')
            return parse(published?.style)
        return <></>;
    }, [page]);


    return (
        // <div className='questionnaire-start'>
        //     {styleRender}
        <>
            {styleRender}
            <div className='questionnaire-wrapper'>
                <Form onSubmit={handleSubmit} className='m-auto' onBlur={handleBlur} form={form}>
                    
                    <br />
                    <br />
                    {blockRender}  
                    {errorMsg}      
                    {submitButton}
                    <br />
                </Form>
            </div>
        </>
    )
};