/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import hero from '../images/clinical-research-hero@2x.png';
import heroMobile from '../images/clinical-research-hero-mobile@2x.png';
import heart from '../images/heart-icon@2x.png';
import participateHeart from '../images/participate-heart-picture@2x.png';
import doIQualify from '../images/Do I Qualify Button@2x.png';
import usa from '../images/usa-heart-icon@2x.png';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import Risk from './RiskSection';
import JoinUs from './JoinUs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useAuth from '../hooks/useAuth';
import '../styles/AboutClinicalResearch.css';

const questionnaireLink = '/questionnaire';

const AboutClinicalResearch = () => {
    const { doConversion } = useAuth();
    const [showLoading, setShowLoading] = useState(true);

    const navigate = useNavigate();

    const nav = (page) => {
        // if (page === questionnaireLink)
        //     doConversion();
        navigate(page);
    }
    
    const handleLoad = () => {
        setShowLoading(false);
    }
    
    return (
        <div class='landing animated animatedFadeInUp landing-fade-delay'>
            <section 
                className='learn-more-clinical-section'
                style={!showLoading ? {
                    background: `transparent url('${heroMobile}') 0% 0% no-repeat padding-box`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 1,
                } : {}}
            >
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={8}>
                            <div className='content'>
                                <p className='header'>Learn More About Clinical Studies</p>
                                <hr />
                                <p className='info'>
                                Clinical research studies are carefully designed and conducted in people to find out if a 
                                medicine, test, or treatment is safe and effective. Get access to an investigational study 
                                medication that may aid in the prevention of asthma attacks.
                                </p>
                                <br />
                                <div onClick={() => nav('/about')} className='learn-more-button p-[13px]'>
                                    <span >Learn More</span>
                                </div>
                                <div onClick={() => nav(questionnaireLink)} className='learn-more-button-inverted p-[13px]'>
                                    <span >Learn More</span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={0} xl={14} xxl={16}>
                            <LazyLoadImage className='clinical-hero' src={hero} alt='Clinical Studies Graphic' />
                        </Col>
                    </Row>
            </section>
            <Risk />
            <section className='clinical-study-process-section'>
                <div className='content'>
                    <p className='header'>Clinical Study Process</p>
                    <hr />
                    <p className='info'>
                    Although requirements may vary depending on the study, most clinical trials require 
                    completion of a short questionnaire to determine eligibility to participate. 
                    Prequalified candidates are contacted by study staff where study requirements 
                    and details of study participation are discussed. 
                    </p>
                    <br />
                    <p className='info'>
                        For additional information check out our Frequently Asked Questions (FAQs)
                    </p>
                    <br />
                    <div onClick={() => nav('/about')} className='learn-more-button p-[13px]'>
                        <span >Learn More</span>
                    </div>
                </div>
            </section>
            <JoinUs />
            {showLoading && <img width={2} alt='loading' src={hero} onLoad={handleLoad} />}
        </div>
    )
};

export default AboutClinicalResearch;