/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAuth from '../hooks/useAuth';
import questionnaireHero from '../images/questionnaire-hero.svg';
import '../styles/Questionnaire.css';
import { useNavigate } from 'react-router-dom';

const AlreadyScreened = () => {

    const { setUser } = useAuth();
    const navigate = useNavigate();

    const nav = (path) => {
        navigate(path);
    }

    setUser();

    return (
        <div>
            <div className='header-section' style={{ backgroundImage: `url('${questionnaireHero}')` }}>
                <div className='content'>
                    <p className='header'>Already Submitted</p>
                    <p className='info'>
                    You have already submitted for Priority Panel. Thank you again for your interest. If you would like to 
                    see what clinical trials are currently available through CVS Health Clinical Trial Services™, click below.
                    </p>
                    <p>
                        <div onClick={() => nav('/questionnaire')} className='conversion-button active-trials do-i-qualify-button p-[13px]'>
                            <span>CVS Active Trials</span>
                        </div>
                    </p>
                    <br />
                </div>
            </div>
        </div>
    )
};

export default AlreadyScreened;