/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import hero from '../images/about-hero@2x.png';
import heart from '../images/heart-icon@2x.png';
import impactHero from '../images/involvement-pic@2x.png';
import howItWorksHero from '../images/how-to-participate@2x.png';
import doIQualify from '../images/Do I Qualify Button@2x.png';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import Risk from './RiskSection';
import JoinUs from './JoinUs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useAuth from '../hooks/useAuth';
import '../styles/About.css';

const questionnaireLink = '/questionnaire';

const About = () => {
    const { doConversion } = useAuth();

    const [showLoading, setShowLoading] = useState(true);

    const navigate = useNavigate();

    const nav = (page) => {
        //if (page === questionnaireLink)
            //doConversion();
        navigate(page);
    };

    const handleLoad = () => {
        setShowLoading(false);
    }
    
    return (
        <div class='landing animated animatedFadeInUp landing-fade-delay'>
            <section 
                className='about-banner-section'
                style={!showLoading ? {
                    
                    background: `transparent url('${hero}') 0% 0% no-repeat padding-box`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 1,
                } : {}}
            >
                <div className='content'>
                    <Row gutter={16}>
                        <Col xs={0} sm={0} md={0} lg={10} xl={8} xxl={12}>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={12}>
                            
                                <p className='header'>
                                    About the BATURA Asthma Study
                                </p>
                                <p className='header'>
                                    <hr />
                                </p>
                                <p className='info'>
                                Get access to an investigational study medication aimed at reducing the risk for asthma attacks through a clinical study.
                                </p>
                                <p>
                                    <img 
                                        alt='Do I Qualify Button' 
                                        className='ml-auto do-i-qualify-button pt-5' 
                                        src={doIQualify} 
                                        onClick={() => nav(questionnaireLink)}
                                    />
                                </p>
                        </Col>
                    </Row>
                </div>
            </section>
            <Risk />
            <section 
                className='about-about-section'
            >
                <div className='content'>
                    <Row gutter={16}>
                        <Col xl={11} xxl={11}>
                            <img className='heart-logo' src={heart} />
                            <p className='header'>About our BATURA Asthma Study</p>
                           
                        </Col>
                        <Col xl={13} xxl={13}>
                            <div className='info'>
                                <p>
                                    The BATURA study is evaluating two investigational asthma medications, one 
                                    called PT027, and another investigational drug called PT007. The study is 
                                    being used to get a better understanding of the effects of the investigational 
                                    medication on potentially reducing the risk of severe asthma attacks. 
                                    Participants will be randomized into two groups. Group 1 will receive 
                                    the study medication PT027, and group 2 will receive the other investigational 
                                    asthma medication PT007. Researchers want to find out if the study medication 
                                    is safe and effective at potentially reducing the symptoms and inflammation 
                                    that trigger an asthma attack.
                                </p>
                                <br />
                                <p> 
                                    This study is double-blind which means 
                                    neither the study staff nor you will know which investigational medication 
                                    you receive. All participants will be given an inhaler as part of the study 
                                    medication, to be used as needed. Study participation may last from 12 – 
                                    52 weeks, depending on how many asthma attacks the participant has 
                                    throughout the duration of the study. This study will be virtual. 
                                    There are no scheduled in person visits required and the study medications 
                                    will be shipped directly to your home. All health tests and medical checks 
                                    will be provided via phone/video call with the study team. 
                                </p>
                                <div className='sub-header'>
                                    Find out if you qualify and learn more today
                                </div>
                                <img 
                                    alt='Do I Qualify Button' 
                                    className='do-i-qualify-button' 
                                    src={doIQualify} 
                                    onClick={() => nav(questionnaireLink)}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='impact-content-section'>
                <div className='content'>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={12}>
                            <img className='impact-graphic' alt='hug graphic' src={impactHero} />
                        </Col>
                        <Col lg={13} xl={13} xxl={12}>
                            <p className='header'>What Will Be Involved?</p>
                            <div className='info'>
                                <p>
                                    Eligible participants will: 
                                </p>
                                <p>
                                    <ul className='list-content'>
                                        <li>Have the investigational study medication shipped directly to their home</li>
                                        <li>Report on any asthma attacks via a smartphone app</li>
                                        <li>Attend 8 virtual visits with the study team</li>
                                    </ul>
                                </p>
                                <p> 
                                    <br />
                                    <div onClick={() => nav(questionnaireLink)} className='enroll-today-button-inverted p-[13px]'>
                                        <span >Enroll Today</span>
                                    </div>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section 
                className='about-about-section impact-sub-section'
            >
                <div className='content'>
                    <Row gutter={0}>
                        <Col xl={10} xxl={10}>
                            <img className='heart-logo' src={heart} />
                            <p className='header'>Impact of The BATURA Asthma Study</p>
                           
                        </Col>
                        <Col xl={14} xxl={14}>
                            <div className='info'>
                                <p>
                                Participation in this study will help us advance research. Participants will 
                                contribute to the process of potentially developing a new study medication 
                                for the potential prevention of asthma attacks, therefore possibly preventing 
                                these attacks in young adolescents and adults. They may also benefit from 
                                access to virtual medical evaluations/assessments relating to the study 
                                procedure, access to study doctors, virtual medical visits, virtual physical 
                                exams, and more. This study medication is being researched for the potential 
                                prevention of asthma attacks in adolescents and adults 12 years of age and older. 
                                Join us in fighting asthma attacks.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='how-it-works-section'>
                <div className='content'>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <p className='header'>How to Participate</p>
                            <hr />
                            <p className='info'>
                            If you or someone you know meet the requirements, you may be eligible to participate in 
                            this clinical research study.
                            </p>
                            <br />
                            <div onClick={() => nav(questionnaireLink)} className='enroll-today-button p-[13px]'>
                                <span>Enroll Today</span>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <LazyLoadImage className='how-it-works-hero' alt='How it works graphic' src={howItWorksHero} />
                        </Col>
                    </Row>
                </div>
            </section>
            <JoinUs />
            {showLoading && <img width={2} alt='loading' src={hero} onLoad={handleLoad} />}
        </div>
    )
};

export default About;