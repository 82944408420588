/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import hero from '../images/hero-background-mobile.png';
import heart from '../images/heart-outline.svg';
import doIQualify from '../images/Do I Qualify Button@2x.png';
import bullet from '../images/bullet-point@2x.png';
import aboutPhoto from '../images/hero-background@2x.png';
import impactsOfAsthma from '../images/impacts-of-asthma-col-1@2x.png';
import howToParticipate from '../images/qualify-photo@2x.png'
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useProgressiveImage from '../hooks/useProgressiveImage';
import JoinUs from './JoinUs';
import useAuth from '../hooks/useAuth';
import { gtag } from 'ga-gtag';
import mapIcon from '../images/icon-1.svg';
import handLoveIcon from '../images/icon-2@2x.png';
import medicineIcon from '../images/icon-3@2x.png';
import '../styles/Landing.css';

const Landing = () => {

    const { doConversion } = useAuth();
    const navigate = useNavigate();

    const nav = (page) => {
        // if (page === questionnaireLink)
        //     doConversion();
            
        navigate(page);
    };

    const questionnaireLink = '/questionnaire';

    const heroSrc = useProgressiveImage(hero);
    
    const [heroReady, setHeroReady] = useState(false);

    useEffect(() => {
        if (window.location.hostname.toLowerCase().endsWith('cvsapol1study.com'))
            gtag('event', 'conversion', {
                'allow_custom_scripts': true,
                'send_to': 'DC-6615255/cvsna0/clini00+standard'
            });
    }, []);

    useEffect(() => {
        if (!!heroSrc)
            setTimeout(() => {
                setHeroReady(true);
            }, 100);
    }, [heroSrc]);
    
    return (
        <div class='landing animated animatedFadeInUp landing-fade-delay'>
            <section 
                className='banner-section'
                style={heroReady ? {
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 1,
                } : {}}
            >
                <div className='content'>
                    <p className='header'>
                    Get Matched With the Right Clinical Trial for You or Your Loved One
                    </p>
                    <img src={heroSrc} className='mobile-hero' />
                    <p className='info'>
                    Be the first to know about new clinical trial opportunities near 
                    you. Tell us about yourself to be notified of clinical trials. 
                    
                    </p>
                    <p className='info'>
                    To get started simply fill out a short questionnaire that will help us get a 
                    better understanding of your medical history and any current health 
                    conditions you may be experiencing. You can sign up for yourself or for 
                    your loved ones. 
                    </p>
                    <p className='info'>
                    This information will allow us to notify you of any new or current enrolling clinical 
                    trials that you or your loved ones may qualify for.
                    </p>
                    <p>
                    <div onClick={() => nav('/questionnaire')} className='sign-up-link'>
                        <span>Sign Up For Our Priority Panel</span>
                    </div>
                    </p>
                </div>
            </section>
            <section 
                className='about-section'
            >
                <Row>
                    <Col md={12} lg={12} xl={12} xxl={12}>
                        <img alt='Banner' className='about-photo' src={aboutPhoto} />
                    </Col>
                    <Col md={12} lg={12} xl={12} xxl={12}>
                        <div className='content'>
                            <p className='header'>Get Matched With the Right Clinical Trial for You or Your Loved One</p>
                            <div className='info'>
                                <p>
                                Be the first to know about new clinical trial opportunities near 
                                you. Tell us about yourself to be notified of clinical trials. 
                                
                                </p>
                                <p>
                                To get started simply fill out a short questionnaire that will help us get a 
                                better understanding of your medical history and any current health 
                                conditions you may be experiencing. You can sign up for yourself or for 
                                your loved ones. 
                                </p>
                                <p>
                                This information will allow us to notify you of any new or current enrolling clinical 
                                trials that you or your loved ones may qualify for.
                                </p>
                                <div onClick={() => nav('/questionnaire')} className='sign-up-link'>
                                    <span>Sign Up For Our Priority Panel</span>
                                </div>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
            </section>
            <section 
                className='how-to-participate-section'
            >
                <div className='content'>
                    <div className='why-enroll'>
                        <p className='header'><img alt='heart' src={heart} />&nbsp;&nbsp;Why Should I Enroll in Priority Panel?</p>
                        <Row gutter={[32, 16]}>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                <div className='card'>
                                    <LazyLoadImage src={mapIcon} alt='Compensation Graphic' />
                                    <p className='header grid'>Get Notified About Clinical Trials Near You</p>
                                    <p className='info'>You'll be among the first to know about any CVS Clinical Trial Services studies that may be right for you.</p>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                <div className='card'>
                                    <LazyLoadImage src={handLoveIcon} alt='Contribute to Research Graphic' />
                                    <p className='header grid'>Get Actively Involved in Your Own or Loved One's Health</p>
                                    <p className='info'>Participating in a clinical trial helps advance medical research with the potential to help you and others.</p>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                <div className='card'>
                                    <LazyLoadImage src={medicineIcon} alt='Health Information Graphic' />
                                    <p className='header grid'>Potential Access to Investigational Study Medications.</p>
                                    <p className='info'>
                                    Potential Access to Investigational Study Medications
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='bleed'></div>
                    <div className='spacer'></div>
                    <div className='enroll-information'>
                        <div className='content'>
                            <Row gutter={17}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <div className='header'>
                                    Learn More About Clinical Trials 
                                    </div>
                                    <div className='info'>
                                    New trials are constantly looking to recruit participants. With your 
                                    help researchers can evaluate new study medications that may result 
                                    in clinical discoveries. This site enables you to be at the forefront of information about you and your loved one’s health.
                                    </div>
                                    <br />
                                    <div className='info'>
                                        <div onClick={() => nav('/questionnaire')} className='sign-up-link'>
                                            <span>Frequently Asked Questions (FAQs)</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                    <div className='header'>
                                    Our Mission    
                                    </div>
                                    <div className='info'>
                                    At CVS Health Clinical Trial Services™, our mission is to make clinical 
                                    trials more accessible to all people. We work to do this by improving 
                                    engagement, health outcomes, and trial efficiency. We can make a difference. 
                                    </div>
                                    <br />
                                    <div className='info'>
                                    We're here to help you better understand what clinical trials are, why they are important, and how you can take part.
                                    </div>
                                    <br />
                                    <div className='info'>
                                        <div onClick={() => nav('/questionnaire')} className='sign-up-link'>
                                            <span>CVS Clinical Trials</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </section>
            <JoinUs />
        </div>
    )
};

export default Landing;