import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const SourceDirectMail = () => {

    const nav = useNavigate();

    const { setSource } = useAuth();


    useEffect(() => {
        const allowedDoms = ['cvsasthma.com', 'cvsasthmacallcenter.com', 'cvsasthmastudy.com'];
        const currDom = window.location.hostname.toLowerCase();
        if (allowedDoms.some(x => currDom.endsWith(x)))
            setSource('Direct Mail');

        nav('/', { replace: true });
    }, [nav, setSource]);

    return <></>;
};

export default SourceDirectMail;