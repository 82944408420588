/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAuth from '../hooks/useAuth';
import heart from './../images/white-heart.png';

const NoSite = () => {

    const { user, setUser } = useAuth();

    // if (!user?.object) {
    //     window.location.replace('/');
    // }

    return (
        <div style={{ marginTop: '3.5rem' }} className='animated animatedFadeInUp fadeInUp my-12 screener-banner px-2'>
            <div className='header'>
                Thank you for your interest in the BATURA study. 
            </div>
            <div className='sub-header'>
                We value your or the potential participant's interest to contribute to clinical research through participation. While it appears that you or the participant meet preliminary criteria, we unfortunately do not have a site location near you or the potential participant. We will contact you or the participant if a site becomes available in the future.
            </div>
            <div className='sub-header'>
                Thank you and have a great day.
            </div>
            <hr />
            <img alt='CVS heart logo' className='white-heart pb-10' src={heart} />
        </div>
    )
};

export default NoSite;