import React from 'react';
import { ServaScreener} from 'serva-questionnaire-render';
import questionnaireHero from '../images/questionnaire-hero.svg';
import '../styles/Questionnaire.css';

const Questionnaire = () => {
    return (
        <div className='m-auto'>
            <div className='header-section' style={{ background: `transparent url('${questionnaireHero}') 0% 0% no-repeat padding-box` }}>
                <div className='content'>
                    <p className='header'>Priority Panel Registration</p>
                    <p className='info'>
                    Thank you for your interest in Priority Panel. Please fill in the information below so you can be contacted about future clinical trials in your area of interest. You can enter information for yourself or for a loved one. Joining Priority Panel is easy and free. We'll keep your information confidential and will only contact you about clinical trials that are relevant to your health status.
                    </p>
                </div>
            </div>
            <ServaScreener qGuid='993120e3-6ce5-4096-a8a2-4ae064c2863b' />
        </div>
    )
};

export default Questionnaire;