import React, { useEffect, useLayoutEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import ContextProvider from '../../auth/Auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import queryString from 'query-string';
const enableLock = false;
const queryClient = new QueryClient();


/**
 * Auto-scroll to top on page transition
 * @param {props.children} App 
 * @returns 
 */
const Wrapper = ({ children }) => {
    const { setProgress, isAuthenticated, setExternalId } = useAuth();
    const nav = useNavigate();


    const checkLock = () => {
        if (enableLock && !isAuthenticated && location.pathname !== '/login') 
            nav('/login');
    }

    const location = useLocation();
    
    checkLock();

	useLayoutEffect(() => {
        checkLock();
	  	document.body.scrollTo(0, 0);
        window.scrollTo(0, 0);
        //ReactGA.send({ hitType: 'pageview', page: location.pathname });
        setProgress();
        const { search } = location;
        const params = queryString.parse(search);
        if (params.oppty_id) {
            setExternalId(params.oppty_id);
        }
	}, [location.pathname, setExternalId]);

    useEffect(() => {
        checkLock();
    }, []);

	return children
} 


const MainLayout = () => {
    return (
        <QueryClientProvider client={queryClient}>
			<ContextProvider>
                <Navbar />
                <Wrapper>
                    <Outlet />
                </Wrapper>
                <Footer />
            </ContextProvider>
        </QueryClientProvider>
    );
};

export default MainLayout;