/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAuth from '../hooks/useAuth';
import questionnaireHero from '../images/questionnaire-hero.svg';
import '../styles/Questionnaire.css';
import { useNavigate } from 'react-router-dom';

const DeclinedParticipation = () => {

    const { setUser } = useAuth();
    const navigate = useNavigate();

    const nav = (path) => {
        navigate(path);
    }

    setUser();

    return (
        <div>
            <div className='header-section' style={{ backgroundImage: `url('${questionnaireHero}')` }}>
                <div className='content'>
                    <p className='header'>Sorry to Hear That</p>
                    <p className='info'>
                    We understand that you do not wish to leave your information and it has been deleted. If you change your mind, 
                    you can always come back and enroll later. 
                    </p>
                    <br />
                    <p className='info'>If you would like to see what clinical trials are currently 
                    available through CVS Health Clinical Trial Services™, click below.</p>
                    <p>
                        <div onClick={() => nav('/questionnaire')} className='conversion-button active-trials do-i-qualify-button p-[13px]'>
                            <span>CVS Active Trials</span>
                        </div>
                    </p>
                    <br />
                </div>
            </div>
        </div>
    )
};

export default DeclinedParticipation;