import React, { forwardRef, useState } from 'react';
import { Form as AntForm } from 'antd';

const { Item } = AntForm;

export { Item };

export const Form = forwardRef(({ form: customForm, children, onBlur, onSubmit }, ref) => {

    const [internalForm] = AntForm.useForm();

    const form = customForm ?? internalForm;

    const [values, setValues] = useState({});
    
    const handleBlurCapture = (updated) => {
        setValues({ ...values, ...updated });

        onBlur(updated);
    };

    const handleFinish = (event) => {
        form
            .validateFields()
            .then((fields) => {
                onSubmit(fields);
            })
            .catch(err => {
                console.log('validation error: ', err);
            });
      };
    return (
        <AntForm 
            ref={ref}
            scrollToFirstError={true}
            requiredMark={false} 
            layout='vertical' 
            form={form} 
            onFinish={handleFinish} 
            onValuesChange={handleBlurCapture}
        >
            {children}
        </AntForm>
    )
});