/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAuth from '../hooks/useAuth';
import questionnaireHero from '../images/questionnaire-hero.svg';
import '../styles/Questionnaire.css';
import { useNavigate } from 'react-router-dom';

const ScreenQualified = () => {

    const { setUser } = useAuth();
    const navigate = useNavigate();

    const nav = (path) => {
        navigate(path);
    }

    setUser();

    return (
        <div>
            <div className='header-section' style={{ backgroundImage: `url('${questionnaireHero}')` }}>
                <div className='content'>
                    <p className='header'>Success!</p>
                    <p className='info'>
                    Thank you! You successfully enrolled in priority panel. If you would like you can enter information for yourself or 
                    for a loved one. When a clinical trial comes up that you or your loved one might be a candidate for, we will reach 
                    out to you to invite you prescreen for that study.
                    </p>
                    <p>
                        <div onClick={() => nav('/questionnaire')} className='conversion-button add-another do-i-qualify-button p-[13px]'>
                            <span>Add Another Loved One</span>
                        </div>
                    </p>
                    <p className='info'>
                    If you would like to see what clinical trials are currently available through CVS Health Clinical Trial Services™, 
                    click below.
                    </p>
                    <p>
                        <div onClick={() => nav('/questionnaire')} className='conversion-button active-trials do-i-qualify-button p-[13px]'>
                            <span>CVS Active Trials</span>
                        </div>
                    </p>
                    <br />
                </div>
            </div>
        </div>
    )
};

export default ScreenQualified;