/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAuth from '../hooks/useAuth';
import heart from './../images/white-heart.png';

const DQ = () => {

    const { user, setUser } = useAuth();

    // if (!user?.object) {
    //     window.location.replace('/');
    // }

    return (
        <div style={{ marginTop: '3.5rem' }} className='animated animatedFadeInUp fadeInUp my-12 screener-banner'>
            <div className='header'>
                Thank You
            </div>
            <div className='sub-header max-w-[850px] m-auto'>
                <p>
                    We appreciate your interest in the BATURA study.
                </p>
                <br />
                <p>
                    Unfortunately, based upon the information provided,
                    you do not meet the eligibility requirements 
                    for study participation.
                </p>
                <br />
                <p>
                    To qualify for this study, a person must be:
                    <ul className=''>
                        <li>- At least 12 years old</li>
                        <li>- Diagnosed with Asthma</li>
                        <li>- Using a rescue inhaler either alone or in combination with other medication to control asthma symptoms</li>
                        <li>- Otherwise in generally good health</li>
                    </ul>
                </p>
                <br />
                <p>
                    If you know of another person who would be interested in participating, 
                    please forward the link <a target='_blank' className='underline hover:underline' href='#' rel="noreferrer">()</a> to them so they can learn more about the study.
                </p>
                <br />
                <p>We also encourage you or the participant to visit <a className='underline hover:underline' href='www.cvsclinicaltrials.com' target='_blank'>www.cvsclinicaltrials.com</a> for more information about CVS Health Clinical Trial Services.</p>
            </div>
            <hr />
            <div className='info'>Thank you and have a great day.</div>
            <img alt='CVS heart logo' className='white-heart pb-10' src={heart} />
        </div>
    )
};

export default DQ;